<template>
  <!--  -->
  <div
    ref="container"
    :style="addVisible ? 'overflow:hidden;' : ''"
    class="cardContainer"
  >
    <div class="title">名片管理</div>
    <div class="tableContainer">
      <!-- <div class="addWrap">
        <el-button :loading="loading" class="add" @click.stop="allRead" >全部已读</el-button>
      </div> -->

      <el-row>
        <el-col class="table">
          <el-table
            :data="tableData"
            border
            header-cell-class-name="headerClass"
            cell-class-name="cellClass"
            style="width: 100%"
            @row-click="rowClick"
          >
            <!-- <el-table-column
              label="ID"
              width="90"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.readFlag===0" class="noRead"/><span >{{ scope.row.projectId }}</span>
              </template>
            </el-table-column> -->
            <el-table-column
              label="微信昵称"
              width="120"
              prop="nickname"
              align="center"
            />
            <el-table-column label="姓名" min-width="120" align="center">
              <template slot-scope="scope">
                {{ scope.row.name || "无" }}
              </template>
            </el-table-column>
            <el-table-column label="公司名称" min-width="214" align="center">
              <template slot-scope="scope">
                {{ scope.row.company || "无" }}
              </template>
            </el-table-column>
            <el-table-column label="职位" min-width="124" align="center">
              <template slot-scope="scope">
                {{ scope.row.position || "无" }}
              </template>
            </el-table-column>
            <el-table-column label="联系电话" min-width="154" align="center">
              <template slot-scope="scope">
                {{ scope.row.mobile || "无" }}
              </template>
            </el-table-column>
            <el-table-column label="状态" min-width="80" align="center">
              <template>
                <span class="editCommon pointer">查看</span>
                <!-- <span :style="scope.row.readFlag===1?'':'cursor:pointer;'" class="editCommon">{{ scope.row.readFlag===1?'已读':'未读' }}</span> -->
              </template>
            </el-table-column>
          </el-table>

          <!-- </div> -->
        </el-col>
      </el-row>
      <div class="pagination" style="text-align: center">
        <el-pagination
          :total="projectDetail.total"
          :page-size="10"
          layout="prev, pager, next"
          @current-change="clickPage"
        />
      </div>
    </div>
    <!-- <ProjectDetail
      v-if="addVisible"
      ref="project"
      :visible.sync="addVisible"
      :project-id = "projectId"
      :is-area="1"
    /> -->
  </div>
</template>
<script>
import { getCardList } from "@/apis/user";
import { parseTime } from "@/utils/index";
// import ProjectDetail from '@/components/projectDetail'
export default {
  // components: {
  //   ProjectDetail
  // },
  data() {
    return {
      tableData: [],
      projectDetail: "",
      projectId: "",
      current: 1,
      addVisible: false,
      loading: false,
    };
  },
  activated() {
    window.addEventListener("keydown", this.key);
    this.getCardList(this.current);
  },
  // activated() {
  //   window.addEventListener('keydown', this.key)
  //   this.getProjectList(this.current)
  // },
  beforeDestroy() {
    window.removeEventListener("keydown", this.key);
  },
  methods: {
    key(e) {
      if (e.keyCode === 9) {
        // //console.log('tabfalse13')
        e.preventDefault();
      }
    },
    async getCardList(current = 1) {
      // var res = await this.$store.dispatch('getContactList', { current })
      var res = await getCardList({ current });
      // //console.log(res)
      var records = res.result.records;
      records.forEach((item) => {
        item.createTime = parseTime(item.createTime);
      });
      // records.sort(function(a, b) {
      //   return a.readFlag - b.readFlag
      // })
      // //console.log(records)
      this.tableData = records;
      this.projectDetail = res.result;
    },
    rowClick(row) {
      // //console.log(row)
      // this.addVisible = true
      // this.projectId = row.projectId
      // updateProjectReaded({ projectId: row.projectId }).then(() => {
      //   row.readFlag = 1
      //   this.tableData.sort(function(a, b) {
      //     if (a.readFlag === b.readFlag) {
      //       return b.projectId - a.projectId
      //     }
      //     return a.readFlag - b.readFlag
      //   })
      // })
      this.$router.push({
        name: "CardDetail",
        params: {
          cardId: row.id,
        },
      });
    },
    allRead() {
      // if (this.loading) return
      // this.loading = true
      // // //console.log(111)
      // updateAllProjectReaded({ projectType: 1 }).then(() => {
      //   this.$message1('全部查阅完毕', 'success')
      //   this.getProjectList(this.current)
      //   this.loading = false
      // }).catch(() => {
      //   this.loading = false
      // })
    },
    // 页码切换
    clickPage(e) {
      this.getCardList(e);
      this.current = e;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/mixin";
.cardContainer {
  height: calc(100vh - 118px);
  position: relative;

  .title {
    letter-spacing: 2px;
    font-size: 22px;
    line-height: 1;
    color: #333;
    font-weight: bold;
    padding-left: 34px;
    padding-top: 40px;
  }
  .tableContainer .table {
    margin-top: 0;
  }
}
</style>
